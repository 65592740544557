import { gql } from '@apollo/client';

// Get User List
// eslint-disable-next-line import/prefer-default-export
export const GET_IMPORT_USERS = gql`
  query importList($skip: Int, $limit: Int) {
    importList(filter: { skip: $skip, limit: $limit }) {
      count
      data {
        id
        source_file
        failed_file
        status
        updated_count
        created_count
        failed_count
        total
      }
    }
  }
`;
