import { Layout } from 'antd';
import React from 'react';
import './AppHeader.css';
import UserProfile from './UserProfile';

const { Header } = Layout;

const AppHeader = () => {
  return (
    <div className="header-image">
      <Header>
        <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg">
          <div className="d-flex align-items-center" />
        </div>
        <div className="header-notification">
          <UserProfile />
        </div>
      </Header>
    </div>
  );
};
export default AppHeader;
