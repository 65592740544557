import { useStytchSession } from '@stytch/react';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import '../../styles/pages/login.less';

export default withRouter(function ({ history }) {
  const { session } = useStytchSession();
  const isAuthenticated = !!session;

  useEffect(() => {
    if (isAuthenticated !== undefined && isAuthenticated) {
      history.replace('/');
    }
  }, [isAuthenticated, history]);

  return (
    <div className="callback-wrapper">
      <Spin />
    </div>
  );
});
