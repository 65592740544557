import { Form, Input, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import ReactInputMask from 'react-input-mask';
import client from '../../apollo';
import { REGEX } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { CREATE_USER, UPDATE_USER } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const AddUserModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    userData,
    setUserData,
    groupData
    // filter
  } = props;
  if (isUpdate) {
    const selectedItems = [];
    if (userData && userData.groups) {
      userData.groups.map((item) => {
        selectedItems.push(item.id);
        return true;
      });
    }
    const tempGroup = groupData;
    if (selectedItems.length > 0) {
      tempGroup.map((item, index) => {
        if (selectedItems.includes(item.value)) {
          tempGroup[index].disable = true;
        } else {
          tempGroup[index].disable = false;
        }
        return true;
      });
    }
  }

  const [loading, setLoading] = useState(false); // loader
  const [form] = Form.useForm(); // form
  // const [checkedAdmin, setCheckedAdmin] = useState(
  //   // eslint-disable-next-line no-nested-ternary
  //   isUpdate ? userData.is_super_admin : false
  // );
  // eslint-disable-next-line no-unneeded-ternary
  const isCheckedDisable = isUpdate && userData.is_super_admin ? true : false;

  // check admin status
  // const [groupDataFilter, setGroupDataFilter] = useState(groupData);

  // cancel button click
  const handleCancel = () => {
    setUserData();
    setShowModal(false);
    form.resetFields();
  };

  // form submit
  const onUserSubmitFinish = async (values) => {
    let validate = true;
    if (isUpdate) {
      if (!values.is_super_admin) {
        // eslint-disable-next-line
        delete values.is_super_admin;
      }
    }
    if (values?.user_group_roles) {
      const IDs = new Set(values.user_group_roles.map((item) => item.group_id));
      // eslint-disable-next-line no-unused-expressions
      if (IDs.size !== values.user_group_roles.length) {
        message.error('please select different group');
        validate = false;
        return true;
      }
    }
    // eslint-disable-next-line no-param-reassign
    values.email = values.email.toLowerCase();
    const variables = isUpdate ? { ...values, id: userData.id } : { ...values };
    if (validate) {
      setLoading(true);
      try {
        await client.mutate({
          mutation: isUpdate ? UPDATE_USER : CREATE_USER,
          variables: variables
        });
        setUserData();
        form.resetFields();
        setShowModal(false);
        props.reloadData();
      } catch (error) {
        return error;
      } finally {
        setLoading(false);
      }
    }
  };

  // change admin status
  // const setCheckedAdminValue = (val) => {
  //   setCheckedAdmin(val);
  // };

  // Add button click
  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };

  // commented for future use
  // const updateGroupListFilter = () => {
  //   const values = form.getFieldsValue();
  //   let selectedItems = [];
  //   selectedItems =
  //     values.user_group_roles &&
  //     values.user_group_roles.length &&
  //     values.user_group_roles.map((item) => {
  //       return item.group_id;
  //     });

  //   if (selectedItems.length > 0) {
  //     // eslint-disable-next-line
  //     const filteredOptions = groupData.map((item, index) => {
  //       if (selectedItems.includes(item.value)) {
  //         groupData[index].disable = true;
  //       } else {
  //         groupData[index].disable = false;
  //       }
  //       return true;
  //     });
  //     setGroupDataFilter(groupData);
  //   } else {
  //     setGroupDataFilter(groupData);
  //   }
  // };

  // trim form values
  const trimFormValues = (val, key) => {
    const newValue = val.target.value.trim();
    form.setFieldsValue({ [key]: newValue });
  };

  return (
    <Modal
      title={isUpdate ? 'Edit User' : 'Add User'}
      visible={showModal}
      confirmLoading={loading}
      onOk={handleAdd}
      className="dialog users-modal"
      okText={isUpdate ? 'Save' : 'Add'}
      onCancel={handleCancel}
      width={616}
    >
      <Form
        form={form}
        initialValues={userData}
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        <Form.Item rules={[required]} name="first_name" label="First Name">
          <Input
            allowClear
            onBlur={(val) => trimFormValues(val, 'first_name')}
          />
        </Form.Item>
        <Form.Item name="middle_name" label="Middle Name">
          <Input
            allowClear
            onBlur={(val) => trimFormValues(val, 'middle_name')}
          />
        </Form.Item>
        <Form.Item rules={[required]} name="last_name" label="Last name">
          <Input
            allowClear
            onBlur={(val) => trimFormValues(val, 'last_name')}
          />
        </Form.Item>
        <Form.Item rules={[required, email]} name="email" label="Email">
          <Input disabled={isUpdate} />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone_no"
          rules={[
            () => ({
              validator(rule, value) {
                if (value) {
                  // eslint-disable-next-line no-param-reassign
                  value = value.split(' ').join('');
                  const numberPattern = REGEX.PHONE;
                  if (!numberPattern.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('should be a valid phone number');
                  }
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <ReactInputMask
            mask="(999) 999-9999"
            placeholder="(___) ___-____"
            min={10}
            className="col"
            allowClear
          >
            {(inputProps) => <Input {...inputProps} />}
          </ReactInputMask>
        </Form.Item>

        <Form.Item
          name="is_super_admin"
          label="Is Admin"
          valuePropName="checked"
        >
          <Input
            type="checkbox"
            disabled={isCheckedDisable}
            // onChange={(val) => setCheckedAdminValue(val.target.checked)}
          />
        </Form.Item>
        {/* commented for future use */}
        {/* {!checkedAdmin && (
          <>
            <div className="select-group">Select Group</div>
            <Form.List name="user_group_roles" label="Select Group">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.group_id !== curValues.group_id ||
                          prevValues.role !== curValues.role
                        }
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'group_id']}
                          fieldKey={[field.fieldKey, 'group_id']}
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Select style={{ width: 270 }} placeholder="Group">
                            {groupDataFilter.map((item) => {
                              return (
                                <Option
                                  key={item.label}
                                  value={item.value}
                                  disabled={item.disable}
                                >
                                  {item.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'role']}
                        fieldKey={[field.fieldKey, 'role']}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Select style={{ width: 270 }} placeholder="Roles">
                          {ROLES_DROPDOWN.map((item) => {
                            return (
                              <Option
                                key={item.value}
                                value={item.value}
                                disabled={item.disable}
                              >
                                {item.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <DeleteOutlined
                        onClick={() => {
                          remove(field.name);
                          updateGroupListFilter();
                        }}
                      />
                    </Space>
                  ))}
                  <Form.Item>
                    <div
                      className="add-group"
                      onClick={() => {
                        if (fields.length >= groupDataFilter.length) {
                          message.error(
                            `max ${groupDataFilter.length} group allow to add.`
                          );
                        } else {
                          add({ group_id: undefined, role: undefined });
                          updateGroupListFilter();
                        }
                      }}
                    >
                      <PlusOutlined /> Add Group
                    </div>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </>
        )} */}
      </Form>
    </Modal>
  );
};

export default AddUserModal;
