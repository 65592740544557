import { useStytch } from '@stytch/react';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { formValidatorRules, TOKEN, USER } from '../../common/constants';
import { toast } from '../../common/utils';
import '../../styles/pages/login.less';

const { required, email } = formValidatorRules;

const Login = ({ history }) => {
  const stytchClient = useStytch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const response = stytchClient.passwords.authenticate({
      email: values?.email,
      password: values?.password,
      session_duration_minutes: 60
    });
    response
      ?.then((res) => {
        setLoading(false);
        toast({
          message: 'LoggedIn Successfully',
          type: 'success'
        });
        if (res?.user) {
          const isAdmin = res?.user?.trusted_metadata?.role === 'ADMIN';
          if (!isAdmin) {
            toast({
              message: 'Access Denied',
              type: 'error'
            });
            history.replace('/logout');
          } else {
            // eslint-disable-next-line
            localStorage.setItem(USER, JSON.stringify(res?.user));
            // eslint-disable-next-line
            localStorage.setItem(TOKEN, res?.session_jwt);
            history?.replace('/');
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast({
          message: error?.error_message,
          type: 'error'
        });
      });
  };

  return (
    <div className="login-wrapper">
      <div className="login-form-wrapper">
        <div className="header">
          <img src={logo} alt="PRAGERU" title="PRAGERU" />
        </div>
        <div className="login-form">
          <Form
            name="Login"
            onFinish={onFinish}
            size="large"
            layout="vertical"
            form={form}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required, message: 'Please enter email!' }, email]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required, message: 'Please enter password!' }]}
            >
              <Input.Password placeholder="Enter password" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="full-width login-btn"
                htmlType="submit"
                disabled={loading}
                loading={loading}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Login);
