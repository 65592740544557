import { Pagination, Table } from 'antd';
import React from 'react';
import { TABLE_LIMIT } from '../common/constants';
import './TableComponent.less';

const TableComponent = (props) => {
  const {
    loading,
    list,
    columns,
    current,
    total,
    handlePageChange,
    pagination = true
  } = props;
  return (
    <div>
      <Table
        pagination={false}
        loading={loading}
        dataSource={list}
        columns={columns}
        scroll={{ x: true }}
        rowKey={(val) => val.id}
        size="small"
      />
      {pagination && (
        <div className="pagination">
          <Pagination
            current={current}
            defaultPageSize={TABLE_LIMIT}
            total={total}
            onChange={handlePageChange}
            defaultCurrent={1}
          />
        </div>
      )}
    </div>
  );
};

export default TableComponent;
