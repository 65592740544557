import { DownOutlined } from '@ant-design/icons';
import { useStytch } from '@stytch/react';
import { Avatar, Button, Form, Input, Modal, Popover } from 'antd';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { formValidatorRules } from '../../../common/constants';
import { toast } from '../../../common/utils';

const { required } = formValidatorRules;

const UserProfile = (props) => {
  const stytchClient = useStytch();
  const { getCurrentUser } = useContext(AppContext);
  const [changePasswordForm] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { name, emails } = getCurrentUser() || {};

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  const changePasswordLink = () => {
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    changePasswordForm?.resetFields();
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    await stytchClient.passwords
      .resetByExistingPassword({
        existing_password: values?.currentPassword,
        new_password: values?.newPassword,
        session_duration_minutes: 60,
        email: emails?.[0]?.email
      })
      .then(() => {
        setLoading(false);
        toast({
          message: 'Password Changed Successfully',
          type: 'success'
        });
        setIsModalOpen(false);
        props.history.replace('/logout');
      })
      .catch((error) => {
        setLoading(false);
        if (error?.status_code === 401) {
          toast({
            message: 'Incorrect current password.',
            type: 'error'
          });
        } else {
          toast({
            message: error?.error_message,
            type: 'error'
          });
        }
      });
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={changePasswordLink}>Change Password</li>
      <li onClick={() => props.history.replace('/logout')}>Logout</li>
    </ul>
  );

  return (
    <>
      <div className="gx-avatar-row">
        <Popover
          className="d-flex flex-row align-items-center"
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <Avatar className="gx-size-35 gx-pointer mr-2" alt="Avatar" />

          <span className="gx-avatar-name d-flex flex-row align-items-center">
            {name?.first_name} {name?.last_name}
            <DownOutlined className=" gx-fs-xxs ml-2" />
          </span>
        </Popover>
      </div>
      <Modal
        visible={isModalOpen}
        title="Change Password"
        onCancel={handleModalCancel}
        footer={null}
        okText="Change Password"
      >
        <Form
          form={changePasswordForm}
          name="change_password"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={handleUpdate}
        >
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[{ required, message: 'Please enter password!' }]}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required,
                message: 'Please input your new password!'
              }
            ]}
            normalize={formValidatorRules.normalize}
          >
            <Input.Password placeholder="Enter New password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['newPassword']}
            rules={[
              {
                required,
                message: 'Please input your new password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match!'));
                }
              })
            ]}
            normalize={formValidatorRules.normalize}
          >
            <Input.Password placeholder="Enter New password" />
          </Form.Item>
          <div className="d-flex align-center">
            <Button onClick={handleModalCancel}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              loading={loading}
            >
              Change Password
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default withRouter(UserProfile);
