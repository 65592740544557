import { FormOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Card, Tooltip } from 'antd';
import React, { useState } from 'react';
import TableComponent from '../../components/TableComponent';
import './donationWidget.less';
import { GET_DONATION_AMOUNTS } from './graphql/Queries';
import UpdateDonationModal from './UpdateDonationModal';

const DonationWidgetList = () => {
  const [showModal, setShowModal] = useState(false); // modal visbile
  const [donationData, setDonationData] = useState(); // donation data store
  // get Donation widget list and Refetch
  const { data: donationDataList, loading, refetch } = useQuery(
    GET_DONATION_AMOUNTS,
    {
      fetchPolicy: 'network-only'
    }
  );

  // for set Edit Data for modal
  const onEditHandle = (data) => {
    setDonationData(data);
    setShowModal(true);
  };

  // refech data again for update
  const reloadData = () => {
    refetch();
  };

  // set column for table
  const columns = [
    {
      title: 'Amount($)',
      dataIndex: 'amount',
      width: '90%'
    },

    {
      title: 'Action',
      dataIndex: 'id',
      width: 120,
      render: (id, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Button
                type="link"
                className="edit-action-btn-color"
                onClick={() => onEditHandle(record)}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <Card>
      <div className="donation-widget-container">
        {showModal && (
          <UpdateDonationModal
            showModal={showModal}
            setShowModal={setShowModal}
            setDonationData={setDonationData}
            donationData={donationData}
            isUpdate={!!donationData}
            reloadData={reloadData}
          />
        )}

        <div className="flex-wrap align-items-center justify-content-between">
          <div className="title-container">Donation Amount List</div>
        </div>
        <hr />
        <TableComponent
          loading={loading}
          list={donationDataList?.donationAmounts?.donationAmounts}
          columns={columns}
          pagination={false}
        />
      </div>
    </Card>
  );
};

export default DonationWidgetList;
