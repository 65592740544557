import { gql } from '@apollo/client';

// Update Donation Amount
// eslint-disable-next-line import/prefer-default-export
export const UPDATE_DONATION_AMOUNT = gql`
  mutation updateDonationAmount($id: ID!, $amount: Float!) {
    updateDonationAmount(id: $id, amount: $amount) {
      message
    }
  }
`;
