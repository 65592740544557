/* eslint-disable no-console */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, message, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import client from '../../apollo';
import CsvIcon from '../../assets/csv.svg';
import { downloadFromURL, fileUpload, getImageURL } from '../../common/utils';
import { GENERATE_SIGNED_URL } from '../groups/graphql/Mutations';
import IMPORT_USERS from './graphql/Mutations';

const sampleUrl =
  'https://prageru-server-dev.s3.amazonaws.com/import/sample.csv';

const ImportUserModal = (props) => {
  const { showModal, setShowModal, setUserData } = props;
  const { Dragger } = Upload;

  const [form] = Form.useForm(); // form
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const urlUpload = async (fileUrl) => {
    try {
      await client.mutate({
        mutation: IMPORT_USERS,
        variables: { sourceFile: fileUrl }
      });
      setLoading(false);
      props.reloadData(); // This function will refresh grid when update completes
      setShowModal(false);
    } catch (error) {
      setLoading(false);
      setShowModal(false);
      return error;
    }
  };

  const handleUploadFile = () => {
    setLoading(true);
    const fileName = `fileUpload/${file?.name}`;
    const fileType = file?.type;
    getImageURL(file, fileName, fileType, GENERATE_SIGNED_URL).then((res) => {
      if (res) {
        fileUpload(res?.data?.generateSignedUrl?.signedUrl, file)
          .then(() => {
            urlUpload(res?.data?.generateSignedUrl?.getUrl);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  };

  // cancel button click
  const handleCancel = () => {
    setUserData();
    setShowModal(false);
    form.resetFields();
  };

  const onChange = (info) => {
    const {
      file: { name }
    } = info;
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (['csv'].includes(ext)) {
      setFileList([...info?.fileList]);
      setFile(info?.file);
    } else {
      message.destroy();
      message.error(`${info?.file?.name} file is not .CSV file.`);
      form.resetFields();
    }
  };
  return (
    <Modal
      title="Import Users"
      visible={showModal}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      wrapClassName="import-users-modal"
    >
      <Form form={form}>
        <div
          className={`upload-logo
          ${fileList.length > 0 ? 'uploaded-file' : ''}`}
        >
          <Dragger
            showUploadList={{ showPreviewIcon: false }}
            listType="picture-card"
            beforeUpload={() => {
              return false; // For stopping the default upload action of antd
            }}
            fileList={fileList}
            maxCount={1}
            onChange={onChange}
          >
            <p className="ant-upload-drag-icon">
              <PlusOutlined />
            </p>
            <p className="ant-upload-text">
              Choose a CSV file or drag it here.
            </p>
          </Dragger>
        </div>
      </Form>
      <div className="import-user-footer">
        <span className="sample-content">
          <img src={CsvIcon} alt="csv-icon" />
          <span
            className="link-btn"
            onClick={() => downloadFromURL(sampleUrl, 'sample.csv')}
          >
            Sample.csv
          </span>
        </span>
        <Button
          type="primary"
          disabled={!fileList.length}
          loading={loading}
          onClick={handleUploadFile}
        >
          Import
        </Button>
      </div>
    </Modal>
  );
};

export default ImportUserModal;
