import { useStytch } from '@stytch/react';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from '../../common/utils';
import '../../styles/pages/login.less';

const LogoutPage = ({ history }) => {
  const stytchClient = useStytch();

  useEffect(() => {
    stytchClient.session
      .revoke()
      .then(() => {
        toast({
          message: 'Logout Successfully',
          type: 'success'
        });
        // eslint-disable-next-line no-undef
        localStorage.clear();
        history.replace('/');
      })
      .catch(() => {
        // eslint-disable-next-line no-undef
        localStorage.clear();
        history.replace('/');
      });
  }, [stytchClient, history]);

  return (
    <div className="callback-wrapper">
      <Spin />
    </div>
  );
};
export default withRouter(LogoutPage);
