import { message } from 'antd';
import moment from 'moment';
import client from '../apollo';
import { defaultDateFormat, REGEX } from './constants';

export const refreshGrid = () => {
  // eslint-disable-next-line no-undef
  window.dispatchEvent(new Event('refresh-grid'));
};

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`
) => {
  if (datetime && moment && format) {
    return moment(datetime).format(format);
  }

  return datetime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required'
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Should be a valid Number');
      }
      return Promise.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice).toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value.trim() };

// upload image on server
export const fileUpload = async (signedUrl, image) => {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', signedUrl);
      xhr.addEventListener('readystatechange', function () {
        resolve(xhr.response);
      });
      xhr.setRequestHeader('Content-Type', image.type);
      xhr.send(image);
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
};

// get Signed url for image
export const getImageURL = async (file, fileName, fileType, mutation) => {
  try {
    return new Promise((resolve) => {
      client
        .mutate({
          mutation: mutation,
          variables: {
            fileName: fileName,
            contentType: fileType
          }
        })
        .then((data) => {
          resolve(data);
        });
    });
  } catch (error) {
    return error;
  }
};

export const toast = ({ message: content, type }) => {
  message.destroy();
  switch (type) {
    case 'info':
      message.info(content);
      break;
    case 'success':
      message.success(content);
      break;
    case 'warning':
      message.warning(content);
      break;
    case 'error':
      message.error(content);
      break;
    default:
      break;
  }
};

export const downloadFromURL = (uri, name) => {
  // eslint-disable-next-line no-undef
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  // eslint-disable-next-line no-undef
  document.body.appendChild(link);
  link.click();
  // eslint-disable-next-line no-undef
  document.body.removeChild(link);
};
