import { gql } from '@apollo/client';

// Get Donation List
// eslint-disable-next-line import/prefer-default-export
export const GET_DONATION_AMOUNTS = gql`
  query donationAmounts {
    donationAmounts {
      count
      donationAmounts {
        id
        amount
      }
    }
  }
`;
