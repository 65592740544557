import { gql } from '@apollo/client';

// Get Group List
// eslint-disable-next-line import/prefer-default-export
export const GET_GROUPS = gql`
  query groups($skip: Int, $limit: Int, $search: String) {
    groups(filter: { skip: $skip, limit: $limit, search: $search }) {
      count
      groups {
        id
        title
        description
        is_active
        image_url
      }
    }
  }
`;
