/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  GROUPS: '/groups',
  THREADS: '/threads',
  USERS: '/users',
  LOGOUT: '/logout',
  CALLBACK: '/callback',
  LOGIN: '/login',
  IMPORT_USERS: '/import-users',
  DYNAMIC_PAGES: '/dynamic-pages',
  DONATION: '/donation'
};

/*  Modules */
export const MODULES = {
  GROUPS: 'Groups',
  THREADS: 'Threads',
  USERS: 'Users',
  IMPORT_USERS: 'Import Users',
  DYNAMIC_PAGES: 'Dynamic Pages',
  DONATION: 'Donation'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const TABLE_LIMIT = 10;

export const ROLES = {
  ADMIN: 'Admin',
  MODERATOR: 'Moderator',
  USER: 'User'
};

export const ROLES_DROPDOWN = [
  { value: 'MODERATOR', label: 'Moderator', key: 'MODERATOR' },
  { value: 'USER', label: 'User', key: 'USER' }
];

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const CLAIM_URL = 'https://prageru.com/jwt/claims';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  DECIMAL: /^([-]?[1-9][0-9]*|0)$/,
  AMOUNT_WITH_TWO_DECIMAL: /^\d+(\.\d{1,2})?$/
};

export const formValidatorRules = {
  required: (validationMessage) => ({
    async validator(_, value) {
      if (!value?.trim()) {
        throw new Error(validationMessage);
      }
    }
  }),
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        return Promise?.reject(new Error('The input is not a valid E-mail!'));
      }
      return Promise?.resolve();
    }
  }),
  normalize: (value) => value?.trim()
};
