import { gql } from '@apollo/client';

// Update Group
// eslint-disable-next-line import/prefer-default-export
export const UPDATE_GROUP = gql`
  mutation updateGroup(
    $description: String
    $title: String
    $id: ID!
    $is_active: Boolean
    $image_url: String
  ) {
    updateGroup(
      id: $id
      data: {
        description: $description
        is_active: $is_active
        image_url: $image_url
        title: $title
      }
    ) {
      message
    }
  }
`;

// Generate Signed url for upload Group image
// eslint-disable-next-line import/prefer-default-export
export const GENERATE_SIGNED_URL = gql`
  mutation generateSignedUrl($fileName: String!, $contentType: String!) {
    generateSignedUrl(
      data: { fileName: $fileName, contentType: $contentType }
    ) {
      fileName
      signedUrl
      getUrl
    }
  }
`;
