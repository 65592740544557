import { FormOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Card, Input, Tooltip } from 'antd';
import { debounce, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import client from '../../apollo';
import { TABLE_LIMIT } from '../../common/constants';
import TableComponent from '../../components/TableComponent';
import { GET_GROUPS } from '../groups/graphql/Queries';
import AddUserModal from './AddUserModal';
import { GET_USERS } from './graphql/Queries';
import './users.less';

const { Search } = Input;
let debounceJob;

const UserList = () => {
  const [showModal, setShowModal] = useState(false); // modal visbile
  const [userData, setUserData] = useState(); // update data store
  const [groupData, setGroupData] = useState(); // group list for filter
  const [searchValue, setSearchValue] = useState(); // search
  const [searchDebounce, setSearchDebounce] = useState();
  // const [byGroup, setByGroup] = useState(); filter by group name
  const [skip, setSkip] = useState(0); // table skip
  const [page, setPage] = useState(1); // table current page
  // const [filter, setFilter] = useState({}); table filter values
  const [limit, setLimit] = useState(TABLE_LIMIT); // table limit

  // get User list and Refetch
  const { data: userDataList, loading, refetch } = useQuery(GET_USERS, {
    variables: {
      // group_id: byGroup,
      search: trim(searchDebounce),
      skip: skip,
      limit: limit
    },
    fetchPolicy: 'network-only'
  });

  // get Group list for Filter
  useEffect(() => {
    client
      .query({
        query: GET_GROUPS,
        fetchPolicy: 'network-only'
      })
      .then((res) => {
        const list = [];
        const newData = res.data && res.data.groups.groups;
        newData.map((item) => {
          return list.push({
            value: item.id,
            label: item.title,
            disable: false
          });
        });
        setGroupData(list);
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err)
      });
  }, []);

  // for search based on Debounce
  useEffect(() => {
    if (debounceJob) {
      debounceJob.cancel();
    }
    debounceJob = debounce(() => {
      setSkip(0);
      setSearchDebounce(searchValue || undefined);
    }, 300);
    debounceJob();
  }, [searchValue]);

  // for open Modal for add
  // const handleAdd = () => {
  //   setShowModal(true);
  // };

  // for set Edit Data for modal
  const onEditHandle = (datas) => {
    const newData = { ...datas };
    const userGroupRoles = [];
    // const groupDataInitialList =[]
    let isSuperAdmin = false;
    if (newData?.role === 'ADMIN') {
      isSuperAdmin = true;
    }
    // eslint-disable-next-line
    newData.is_super_admin = isSuperAdmin;
    const newUserData = { ...newData, user_group_roles: userGroupRoles };
    setUserData(newUserData);
    setShowModal(true);
  };

  // for search change
  const onSearchChange = (val) => {
    setSearchValue(val);
  };

  // set current page for table
  const handlePageChange = (val, pageLimit) => {
    setPage(val);
    setLimit(pageLimit);
    const newPage = val - 1;
    setSkip(pageLimit * newPage);
  };

  // for group filter change
  // const filterByGroup = (val) => {
  //   setByGroup(val);
  //   setSkip(0);
  //   handlePageChange(1, limit);
  //   setFilter({ ...filter, group_id: val });
  // };

  // refech data again for update
  const reloadData = () => {
    refetch();
  };

  // set column for table
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      width: '25%'
    },
    {
      title: 'Middle Name',
      dataIndex: 'middle_name',
      width: '25%'
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      width: '25%'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '25%'
    },
    // commented for future use
    // {
    //   title: 'In Groups',
    //   dataIndex: 'groups',
    //   width: '30%',
    //   render: (groups) => {
    //     return (
    //       <>
    //         {groups &&
    //           groups.map((item, index) => {
    //             // eslint-disable-next-line
    //             return <Tag key={index}>{item.title}</Tag>
    //             // eslint-disable-next-line
    //           })
    //         }
    //       </>
    //     );
    //   }
    // },

    {
      title: 'Action',
      dataIndex: 'id',
      width: 120,
      render: (id, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Button
                type="link"
                className="edit-action-btn-color"
                onClick={() => onEditHandle(record)}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <div className="users-container">
      <Card>
        {showModal && (
          <AddUserModal
            groupData={groupData}
            showModal={showModal}
            setShowModal={setShowModal}
            setUserData={setUserData}
            userData={userData}
            isUpdate={!!userData}
            reloadData={reloadData}
            filter={{
              // group_id: byGroup,
              search: searchDebounce,
              skip: skip,
              limit: limit
            }}
          />
        )}

        <div className="flex-wrap align-items-center justify-content-between">
          <div className="title-container">Users</div>
          <div className="flex-wrap">
            <div className="pr-10">
              <Search
                value={searchValue}
                placeholder="Search User"
                onChange={(val) => onSearchChange(val.target.value)}
                style={{ width: 300 }}
              />
            </div>
            {/* commented for future use */}
            {/* <div className="pr-10">
              <Select
                placeholder="Filter by Group"
                options={groupData}
                allowClear
                value={byGroup}
                onChange={(val) => filterByGroup(val)}
                style={{ width: 150 }}
              />
            </div> */}
            {/* commented as per task : PRAU-2593 */}
            {/* <div className="pr-10 add-thread-btn">
              <Button type="primary" onClick={handleAdd}>
                Add User
              </Button>
            </div> */}
          </div>
        </div>
        <hr />
        <TableComponent
          loading={loading}
          list={userDataList && userDataList.users && userDataList.users.users}
          columns={columns}
          current={page}
          pageSize={limit}
          total={userDataList && userDataList.users && userDataList.users.count}
          handlePageChange={handlePageChange}
        />
      </Card>
    </div>
  );
};

export default UserList;
