import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { ApolloLink, from } from '@apollo/client/link/core';
import { ErrorLink } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import * as Sentry from '@sentry/react';
import { message, notification } from 'antd';
import { createBrowserHistory } from 'history';
import { get, isObject } from 'lodash';
import { TOKEN } from './common/constants';

const history = createBrowserHistory();
let disableToastTimeout = null;
export const cacheData = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL
});

const toast = ({ message: content, type }) => {
  message.destroy();
  switch (type) {
    case 'info':
      message.info(content);
      break;
    case 'success':
      message.success(content);
      break;
    case 'warning':
      message.warning(content);
      break;
    case 'error':
      message.error(content);
      break;
    default:
      break;
  }
};

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  // eslint-disable-next-line no-undef
  const authorizationToken = localStorage.getItem(TOKEN);
  operation.setContext({
    headers: {
      authorization: authorizationToken ? `Bearer ${authorizationToken}` : null
    }
  });

  return forward(operation);
});

const responseMessageLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { data } = response;

    if (
      data &&
      isObject(data) &&
      Object.keys(data).length > 0 &&
      data[`${Object.keys(data)[0]}`] &&
      data[`${Object.keys(data)[0]}`].message
    ) {
      if (Object.keys(data)[0] === 'forgotUserPassword') {
        if (data[`${Object.keys(data)[0]}`].status !== 'ERROR') {
          setTimeout(() => {
            toast({
              message:
                data[`${Object.keys(data)[0]}`].message ||
                'Operation successful',
              type: 'success'
            });
          }, 1000);
        }
      } else {
        setTimeout(() => {
          const oResponse = data[`${Object.keys(data)[0]}`];

          if (!response) {
            return;
          }

          toast({
            message: oResponse.message || 'Operation successful',
            type: oResponse.status === 'ERROR' ? 'error' : 'success'
          });
        }, 1000);
      }
    }
    return response;
  });
});

const errorLink = new ErrorLink((options) => {
  const { graphQLErrors, networkError, response } = options;
  if (networkError && networkError.statusCode === 405) {
    if (disableToastTimeout) {
      clearTimeout(disableToastTimeout);
    }

    disableToastTimeout = setTimeout(() => {
      if (networkError.result && networkError.result.message) {
        toast({
          message: networkError.result.message,
          type: 'error'
        });
      }
    }, 200);

    history.replace('/logout');
    return;
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    if (graphQLErrors[0].message === 'Not Authorized') {
      if (disableToastTimeout) {
        clearTimeout(disableToastTimeout);
      }
      notification.error({
        message: 'Access Denied'
      });
      disableToastTimeout = setTimeout(() => {
        // eslint-disable-next-line no-undef
        window.location = '/logout';
      }, 500);

      return;
    }
    if (graphQLErrors[0].message === 'User is in-active!') {
      if (disableToastTimeout) {
        clearTimeout(disableToastTimeout);
      }
      notification.error({
        message: 'Access Denied'
      });
      disableToastTimeout = setTimeout(() => {
        // eslint-disable-next-line no-undef
        window.location = '/logout';
      }, 500);

      return;
    }

    const isForBidden =
      get(graphQLErrors[0], 'extensions.code') === 'FORBIDDEN';

    if (!isForBidden) {
      setTimeout(() => {
        // Enable when sentry integrated
        Sentry.captureException(
          new Error(`[Response error]: Message: ${graphQLErrors[0].message}`)
        );
        toast({
          message: graphQLErrors[0].message,
          type: 'error'
        });
      }, 1000);
    }
  } else {
    setTimeout(() => {
      // Enable when sentry integrated
      Sentry.captureException(new Error(`Something went wrong!`));
      toast({
        message: 'Something went wrong!',
        type: 'error'
      });
    }, 1000);
  }

  if (response) {
    response.errors.map((error) => {
      const { message: errorMessage, locations, path, extensions } = error;

      // Enable when sentry integrated
      Sentry.captureException(
        new Error(
          `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
        )
      );

      if (extensions && extensions.code === 'FORBIDDEN') {
        history.push('/access-denied');
      }

      if (
        extensions &&
        (extensions.code === 'UNAUTHENTICATED' || extensions.code === 405)
      ) {
        // eslint-disable-next-line
        window.location = '/logout';
        return;
      }

      if (
        extensions &&
        extensions.code === 'INTERNAL_SERVER_ERROR' &&
        errorMessage === 'Your Session has expired. Please login again!'
      ) {
        // eslint-disable-next-line
        window.location = '/logout';
        return;
      }

      // eslint-disable-next-line no-console
      return console.log(
        `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    // Enable when sentry integrated
    Sentry.captureException(new Error(`[Network error]: ${networkError}`));
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authMiddleware, httpLink])
});

export default client;
