import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import Error404 from '../Error404';
import Logout from '../modules/auth/Logout';
import DonationWidget from '../modules/donationWidget';
import DynamicPages from '../modules/dynamicPages';
import ImportUsers from '../modules/importUsers';
import Users from '../modules/users';

const ContentRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTES.MAIN}
          render={() => <Redirect to={ROUTES.USERS} />}
        />
        <Route exact path={ROUTES.USERS} component={Users} />
        <Route exact path={ROUTES.LOGOUT} component={Logout} />
        {/* <Route exact path={ROUTES.GROUPS} component={Groups} />
        <Route exact path={ROUTES.THREADS} component={Threads} /> */}
        <Route exact path={ROUTES.IMPORT_USERS} component={ImportUsers} />
        <Route exact path={ROUTES.DYNAMIC_PAGES} component={DynamicPages} />
        <Route exact path={ROUTES.DONATION} component={DonationWidget} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
