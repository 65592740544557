import { gql } from '@apollo/client';

// Clear Cache
const CLEAR_CACHE = gql`
  mutation cleanCacheMPC($slug: String!) {
    cleanCacheMPC(slug: $slug)
  }
`;

export default CLEAR_CACHE;
