import { gql } from '@apollo/client';

const IMPORT_USERS = gql`
  mutation importUsers($sourceFile: String!) {
    importUsers(source_file: $sourceFile) {
      message
    }
  }
`;

export default IMPORT_USERS;
