import { Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import client from '../../apollo';
import { REGEX } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { UPDATE_DONATION_AMOUNT } from './graphql/Mutations';

const { required } = formValidatorRules;

const UpdateDonationModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    donationData,
    setDonationData
  } = props;
  const [loading, setLoading] = useState(false); // loader
  const [form] = Form.useForm(); // form

  // cancel button click
  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setDonationData();
  };

  // form submit
  const onUserSubmitFinish = async (values) => {
    setLoading(true);
    const newValues = {
      amount: Number(values.amount)
    };
    const variables = { ...newValues, id: donationData?.id };
    try {
      await client.mutate({
        mutation: UPDATE_DONATION_AMOUNT,
        variables: variables
      });
      setDonationData();
      props.reloadData();
      form.resetFields();
      setShowModal(false);
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  // Add button click
  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };

  // trim form values
  const trimFormValues = (val, key) => {
    const newValue = val.target.value.trim();
    form.setFieldsValue({ [key]: newValue });
  };

  return (
    <Modal
      title={isUpdate ? 'Edit Donation' : 'Add Donation'}
      visible={showModal}
      confirmLoading={loading}
      onOk={handleAdd}
      className="dialog"
      okText={isUpdate ? 'Save' : 'Add'}
      onCancel={handleCancel}
      width={616}
    >
      <Form
        form={form}
        initialValues={donationData}
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        <Form.Item
          rules={[
            required,
            () => ({
              type: 'number',
              validator(rule, value) {
                if (!value) {
                  return Promise.resolve();
                }
                if (
                  !Number(value) ||
                  !REGEX.AMOUNT_WITH_TWO_DECIMAL.test(Number(value))
                ) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('Should be a valid Number');
                }
                return Promise.resolve();
              }
            })
          ]}
          name="amount"
          label="Amount"
        >
          <Input allowClear onBlur={(val) => trimFormValues(val, 'amount')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateDonationModal;
