import { SyncOutlined } from '@ant-design/icons';
import { Button, Card, message, Tooltip } from 'antd';
import React, { useState } from 'react';
import client from '../../apollo';
import TableComponent from '../../components/TableComponent';
import './dynamicPages.less';
import CLEAR_CACHE from './graphql/Mutations';

const DynamicPagesList = () => {
  const [loading, setLoading] = useState(false);

  const onClearCacheHandler = async (e, slugName) => {
    e.preventDefault();

    setLoading(true);
    const variables = { slug: slugName };
    try {
      const response = await client?.mutate({
        mutation: CLEAR_CACHE,
        variables: variables
      });
      const successMessage = response?.data?.cleanCacheMPC;
      if (successMessage) {
        message?.success(successMessage);
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const dataSource = [
    {
      title: 'Mobile App home screen',
      description: 'Mobile App home page cache will be cleared.',
      slug: 'app-home'
    },
    {
      title: 'Mobile App Kids Screen',
      description: 'Mobile App kids page cache will be cleared.',
      slug: 'kids'
    },
    {
      title: 'OTT home screen',
      description: 'OTT home page cache will be cleared.',
      slug: 'ott-home'
    },
    {
      title: 'OTT Kids screen',
      description: 'OTT kids page cache will be cleared.',
      slug: 'ott-kids-page'
    }
  ];

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: 250
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      width: 150
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: 120,
      render: (id, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Clear Cache">
              <Button
                type="link"
                className="edit-action-btn-color"
                onClick={(e) => onClearCacheHandler(e, record?.slug)}
                icon={<SyncOutlined />}
              />
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <div className="dynamic-pages-container">
      <Card>
        <div className="flex-wrap align-items-center justify-content-between">
          <div className="title-container">Dynamic Pages</div>
        </div>
        <hr />
        <TableComponent
          list={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </Card>
    </div>
  );
};

export default DynamicPagesList;
