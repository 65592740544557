import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { TABLE_LIMIT } from '../../common/constants';
import TableComponent from '../../components/TableComponent';
import { GET_IMPORT_USERS } from './graphql/Queries';
import ImportUserModal from './ImportUserModal';
import './importUsers.less';

const ImportUserList = () => {
  const [showModal, setShowModal] = useState(false); // modal visbile
  const [userData, setUserData] = useState(); // update data store
  const [skip, setSkip] = useState(0); // table skip
  const [page, setPage] = useState(1); // table current page
  const [limit, setLimit] = useState(TABLE_LIMIT); // table limit

  // get User list and Refetch
  const { data: importUserDataList, loading, refetch } = useQuery(
    GET_IMPORT_USERS,
    {
      variables: {
        skip: skip,
        limit: limit
      },
      fetchPolicy: 'network-only'
    }
  );

  // for open Modal for add
  const handleAdd = () => {
    setShowModal(true);
  };

  // set current page for table
  const handlePageChange = (val, pageLimit) => {
    setPage(val);
    setLimit(pageLimit);
    const newPage = val - 1;
    setSkip(pageLimit * newPage);
  };

  // refech data again for update
  const reloadData = () => {
    refetch();
  };
  // set column for table
  const columns = [
    {
      title: 'Source File',
      dataIndex: 'source_file',
      width: '15%',
      render: (value) => (
        <a href={value} className="primay-link" download>
          {value}
        </a>
      )
    },
    {
      title: 'Failed File',
      dataIndex: 'failed_file',
      width: '15%',
      render: (value) => (
        <a href={value} className="primay-link" download>
          {value}
        </a>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '15%'
    },
    {
      title: 'Updated Count',
      dataIndex: 'updated_count',
      width: '15%'
    },
    {
      title: 'Created Count',
      dataIndex: 'created_count',
      width: '15%'
    },
    {
      title: 'Failed Count',
      dataIndex: 'failed_count',
      width: '15%'
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 120
    }
  ];

  return (
    <div className="import-users-container">
      <Card>
        {showModal && (
          <ImportUserModal
            showModal={showModal}
            setShowModal={setShowModal}
            setUserData={setUserData}
            userData={userData}
            isUpdate={!!userData}
            reloadData={reloadData}
          />
        )}

        <div className="flex-wrap align-items-center justify-content-between">
          <div className="title-container">Import Users</div>
          <div className="flex-wrap">
            <div className="pr-10 add-import-users-btn">
              <Button type="primary" onClick={handleAdd}>
                Import Users
              </Button>
            </div>
          </div>
        </div>
        <hr />
        <TableComponent
          loading={loading}
          list={importUserDataList?.importList?.data}
          columns={columns}
          current={page}
          pageSize={limit}
          total={importUserDataList?.importList?.count}
          handlePageChange={handlePageChange}
        />
      </Card>
    </div>
  );
};

export default ImportUserList;
